import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"


import ClientReviews from "../sections/ClientReviews/ClientReviews"
import FAQ from "../sections/FAQ/FAQ"
import OurCompany from "../sections/OurCompany/OurCompany"

import OurServices from "../sections/OurServices/OurServices"
import SEO from "../components/seo"
import BulletPoints from "../sections/BulletPoints/BulletPoints"
import headerImg from "../assets/Salona_Consulting_Plitvice_Lake2.jpg"
import Header from "../sections/Header/Header"
import { withTranslation } from "react-i18next"
import i18n from '../i18n'

import "../styles/main.scss"

const IndexPage = ({ t }) => {
  if (i18n.language !== 'es') {
    i18n.changeLanguage('es')
  }
  return (
    <Layout id='our-services'>
      <SEO title={t("TITLE_SERVICES")} metaT={t("META_SERVICES")} />
      <Header
        image={headerImg}
        btnClass='btn-2'
        title={<h1 className='spec'>{t("OUR_SERVICES_TITLE")}</h1>}
        subtitle={t("OUR_SERVICES_SUB")}
        actionTitle={t("OUR_SERVICES_LEARN_MORE")}
        section={"services"}
      />
      <OurServices />
      <BulletPoints />
      <FAQ />
      <ClientReviews background={"white"} />
      <OurCompany />
    </Layout>
  )
}

export default withTranslation()(IndexPage)
